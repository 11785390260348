.h-full__control {
    height: 44px !important;
    border-radius: 0.375rem !important;
}

.css-qbdosj-Input{
    color: #344767 !important;
    font-weight: 400;
    font-family: 'Roboto';
    font-size: 16px;
}

.h-full__placeholder {
    font-size: 16px;
    color: #7B809A;
}