@tailwind base;
@tailwind components;
@tailwind utilities;

.laporan .css-103b10f-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    color: white !important;
}

.Toastify__toast-body {
    font-size: 0.875rem;
}

.MuiSelect-select {
    font-size: 0.875rem;
}

.MuiPickersMonth-monthButton, .MuiPickersYear-yearButton{
    font-size: 0.875rem !important;
    font-weight: 400 !important;
}

.MuiPickersDay-root{
    font-size: 0.875rem !important;
    font-weight: 300 !important;
}

.MuiDayCalendar-weekDayLabel{
    font-size: 0.875rem !important;
    font-weight: 500 !important;
}

.MuiPickersMonth-monthButton.Mui-selected, .MuiPickersYear-yearButton.Mui-selected, .MuiPickersDay-root.Mui-selected{
    background: linear-gradient(195deg, #49a3f1, #1A73E8);
}

.MuiPickersCalendarHeader-labelContainer{
    font-size: 1rem !important;
}

.MuiToolbar-regular{
    flex-direction: row !important;
}